import React from 'react';
import styled from 'styled-components';
import Fade from 'react-reveal/Fade';
function Header() {
	const scroll = () => {
		const section = document.getElementById('products');
		section.scrollIntoView({ behavior: 'smooth', block: 'start' });
	};
	return (
		<Container>
			<Fade bottom duration={1000} delay={0}>
				<Title>Custom spigots, and much more!</Title>
			</Fade>

			<Fade bottom duration={1000} delay={200}>
				{' '}
				<Text>
					We offer you variety of spigot forks for multiple Minecraft
					versions! Our spigots are optimized and we have good
					customizability and features. We take your server to a whole
					new level!
				</Text>
			</Fade>

			<ButtonContainer>
				<Fade bottom duration={1000} delay={400}>
					<Button onClick={scroll}>Our products</Button>
				</Fade>
			</ButtonContainer>
		</Container>
	);
}

const Container = styled.div`
	width: 50%;
	align-items: center;
	display: flex;
	flex-direction: column;
	margin-top: 200px;
	@media only screen and (max-width: 1200px) {
		margin-top: 150px;
		width: 100%;
	}
`;

const Title = styled.h1`
	display: flex;
	align-items: center;

	font-size: 62px;
	line-height: 1.2;
	font-weight: 600;
	letter-spacing: -1.73px;
	text-shadow: 0 0 99px #ffffff29;

	@media only screen and (max-width: 1200px) {
		font-size: 42px;
	}
`;

const Text = styled.p`
	color: white;
	width: 100%;
	opacity: 0.6;
	margin-top: 20px;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
		line-height: 1.7;
		font-weight: 300;
		letter-spacing: -0.29px;
	}
`;

const ButtonContainer = styled.div`
	display: flex;
	justify-content: left;
	width: 100%;
`;

const Button = styled.button`
	color: white;
	opacity: 0.6;
	font-size: 18px;
	margin-top: 20px;

	cursor: pointer;
	border-radius: 100rem;
	padding: 0.2rem 2rem;
	border: solid 2px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(101deg, var(--scalebound-blue), var(--scalebound-green));
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px var(--scalebound-main) inset;
	@media only screen and (max-width: 1200px) {
		font-size: 14px;
	}
`;

export default Header;
