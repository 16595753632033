import React, { useState } from 'react';
import axios from 'axios';
import ReactTimeAgo from 'react-time-ago/commonjs/ReactTimeAgo';
import styled from 'styled-components';
import { GrLicense } from 'react-icons/gr';
import { AiOutlineQuestionCircle } from 'react-icons/ai';
import Fade from 'react-reveal/Fade';
const initialState = {
	license: '',
	success: '',
	err: '',
	reason: '',
	discordId: '',
	type: '',
	successMsg: '',
};
function Checker() {
	const [data, setData] = useState(initialState);

	const { license, success, err, reason, discordId, type, successMsg } = data;

	const handleChangeInput = (e) => {
		const { value, name } = e.target;
		setData({ ...data, [name]: value, err: '' });
	};

	const handleSubmit = async (e) => {
		e.preventDefault();
		if (license.length < 10) {
			return setData({
				...data,
				err: 'Invalid license key!',
				success: '',
			});
		}
		try {
			const res = await axios.post('/keychecker/api', {
				license,
			});
			setData({ ...data, err: '', success: res.data.msg });
		} catch (err) {
			err.response.data.msg &&
				setData({ ...data, err: err.response.data.msg, success: '' });
		}
	};

	const requestReset = async (e) => {
		e.preventDefault();

		if (!type || type === '') {
			return setData({
				...data,
				successMsg: '',
				err: 'Invalid type. Refresh page.',
			});
		}

		if (!discordId || discordId.length < 17) {
			return setData({
				...data,
				successMsg: '',
				err: 'Invalid Discord ID!',
			});
		}

		if (!reason || reason.length < 10) {
			return setData({
				...data,
				successMsg: '',
				err: 'Invalid reason!',
			});
		}
		try {
			await axios.post('/keychecker/api/resetrequest', {
				license,
				type,
				reason,
				discordId,
			});
			setData({
				...data,
				err: '',
				reason: '',
				successMsg: 'Successfully requested reset',
			});
		} catch (err) {
			err.response.data.msg &&
				setData({
					...data,
					successMsg: '',
					err: err.response.data.msg,
				});
		}
	};

	const renderHwidList = () => {
		if (!success) {
			return null;
		}
		if (success.hwidList?.length === 0) {
			return (
				<p
					style={{
						color: '#a6b0cf',
						padding: '12px',
						fontSize: '0.8125rem',
					}}
				>
					No results...
				</p>
			);
		}
		return success.hwidList.map((hwid) => <p key={hwid}>- {hwid}</p>);
	};

	const renderIpList = () => {
		if (!success) {
			return null;
		}
		if (success.ipList?.length === 0) {
			return (
				<p
					style={{
						color: '#a6b0cf',
						padding: '12px',
						fontSize: '0.8125rem',
					}}
				>
					No results...
				</p>
			);
		}
		return success.ipList.map((ip) => <p key={ip}>- {ip}</p>);
	};
	return (
		<Container>
			<Section className="left">
				<Fade bottom duration={800} delay={0}>
					<Title>License checker</Title>
				</Fade>
				<Fade bottom duration={800} delay={200}>
					<Text>
						Enter your license key to view your usage details!
					</Text>
				</Fade>
			</Section>

			<Section className="right">
				{success ? (
					<>
						<Title
							style={{
								fontSize: '22px',
								fontWeight: 'normal',
								color: 'var(--scalebound-blue)',
							}}
						>
							Your license key details
						</Title>
						<Text>{license}</Text>
						<Text>Owner: {success.owner}</Text>
						<Text>
							Created at:{' '}
							<ReactTimeAgo
								date={Date.parse(success.createdAt)}
								locale="en-US"
							/>
						</Text>
						<Text>
							Latest request:{' '}
							{success.lastUsed ? (
								<ReactTimeAgo
									date={Date.parse(success.createdAt)}
									locale="en-US"
								/>
							) : (
								'None'
							)}
						</Text>
						<Text>Product: {success.product}</Text>
						<Text style={{ marginTop: '10px' }}>HWID-List:</Text>
						<Text style={{ color: 'var(--scalebound-blue)' }}>
							{success.hwidList && renderHwidList()}
						</Text>

						<Text style={{ marginTop: '10px' }}>IP-List:</Text>
						<Text style={{ color: 'var(--scalebound-blue)' }}>
							{success.ipList && renderIpList()}
						</Text>
						<Text></Text>
						<Error>{err ? err : null}</Error>
						<Success>{successMsg ? successMsg : null}</Success>
						{type ? (
							<>
								<form action="" autoComplete="off">
									{' '}
									<div className="inputWithIcon">
										<AiOutlineQuestionCircle
											style={{
												color: 'var(--scalebound-blue)',
											}}
										/>

										<LicenseInput
											onChange={handleChangeInput}
											value={discordId}
											name="discordId"
											maxLength="200"
											type="text"
											className="form-control"
											placeholder="Discord ID"
										/>
									</div>
									<div
										className="inputWithIcon"
										style={{ marginTop: '10px' }}
									>
										<AiOutlineQuestionCircle
											style={{
												color: 'var(--scalebound-blue)',
											}}
										/>

										<LicenseInput
											onChange={handleChangeInput}
											value={reason}
											name="reason"
											maxLength="200"
											type="text"
											className="form-control"
											placeholder="Reason for the reset"
										/>
									</div>
									<LicenseSubmit
										name="reset"
										style={{
											background: '#0066ff',
										}}
										onClick={requestReset}
									>
										Submit request
									</LicenseSubmit>
								</form>
							</>
						) : null}
						{type ? null : (
							<>
								{' '}
								<ButtonRow>
									<LicenseSubmit
										name="hwid"
										onClick={() =>
											setData({
												...data,
												type: 'hwid',
											})
										}
									>
										Request HWID-reset
									</LicenseSubmit>
									<LicenseSubmit
										name="ip"
										onClick={() =>
											setData({
												...data,
												type: 'ip',
											})
										}
									>
										Request IP-reset
									</LicenseSubmit>
								</ButtonRow>
								<LicenseSubmit
									name="license"
									onClick={() =>
										setData({
											...data,
											type: 'license',
										})
									}
									style={{
										marginTop: '10px',
										background: '#0066ff',
									}}
								>
									Request license reset
								</LicenseSubmit>
							</>
						)}
						<LicenseSubmit onClick={() => setData(initialState)}>
							Back
						</LicenseSubmit>
					</>
				) : (
					<>
						{' '}
						<Fade bottom duration={800} delay={400}>
							{' '}
							<TitleContainer>
								<TitleSmall>Submit your</TitleSmall>
								<Text style={{ marginBottom: '5px' }}>
									licensekey to access your details
								</Text>
							</TitleContainer>
							<ErrorContainer>
								<Error>{err ? err : null}</Error>
								<Success>
									{successMsg ? successMsg : null}
								</Success>
							</ErrorContainer>
						</Fade>
						<Fade bottom duration={800} delay={400}>
							{' '}
							<form
								className="inputWithIcon"
								autoComplete="off"
								onSubmit={handleSubmit}
							>
								<GrLicense />
								<LicenseInput
									onChange={handleChangeInput}
									name="license"
									maxLength="35"
									value={license}
									type="text"
									className="form-control"
									placeholder="License key"
								></LicenseInput>
							</form>
						</Fade>
						<Fade bottom duration={800} delay={600}>
							<div className="btncontainer">
								<LicenseSubmit onClick={handleSubmit}>
									Submit
								</LicenseSubmit>{' '}
							</div>
						</Fade>
					</>
				)}
			</Section>
		</Container>
	);
}
const Error = styled.p`
	color: #dc3545;
`;
const Success = styled.p`
	color: #6fbf73;
`;
const ButtonRow = styled.div`
	display: flex;
	width: 460px;
	gap: 5px;
	@media only screen and (max-width: 768px) {
		width: 100%;
	}
`;
const Container = styled.div`
	display: flex;
	height: calc(100vh - 121px);
	@media only screen and (max-width: 1200px) {
		flex-direction: column;
		.right {
			justify-content: flex-start;
			margin-bottom: 5rem;
		}
		.left {
			justify-content: flex-end;
			margin-bottom: 1rem;
		}
	}
`;
const Section = styled.div`
	width: 50%;
	height: 100%;
	display: flex;
	justify-content: center;
	text-align: left;
	align-items: left;
	flex-direction: column;
	@media only screen and (max-width: 1200px) {
		width: 100%;
		text-align: center;
		align-items: center;
	}
	.inputWithIcon {
		position: relative;
		@media only screen and (max-width: 1200px) {
			display: flex;
			max-width: 100%;
			justify-content: center;
		}
	}
	.btncontainer {
		@media only screen and (max-width: 1200px) {
			width: 100%;
			display: flex;
			max-width: 460px;
		}
	}

	.inputWithIcon svg {
		position: absolute;
		left: 0;
		margin: 12px 12px;
		z-index: 100;
	}
	.inputWithIcon path {
		stroke: #4d8cf4;
	}

	.inputWithIcon input[type='text'] {
		padding-left: 40px;
	}
`;
const Title = styled.h1`
	align-items: center;
	font-size: 1.5rem;
	line-height: 1.2;
	font-weight: 600;
	letter-spacing: -1.73px;
`;
const TitleSmall = styled.h2`
	align-items: center;
	font-size: 1.5rem;
	line-height: 1.2;
	letter-spacing: -1.73px;
`;
const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 460px;
	justify-content: center;
	align-items: center;
	text-align: center;
	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;
const ErrorContainer = styled.div`
	display: flex;
	flex-direction: column;
	width: 460px;
	justify-content: center;
	align-items: center;
	text-align: center;
`;
const Text = styled.p`
	color: white;
	width: 100%;
	opacity: 0.6;
	margin-top: 5px;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
	}
`;

const LicenseInput = styled.input`
	background-color: #25243e;
	border: none;
	height: 42px;
	width: 100%;
	padding-left: 40px;
	padding-right: 20px;
	width: 400px;
	box-shadow: none;
	border-radius: 30px;
	outline: none;
	font-size: 0.8125rem;
	font-weight: 400;
	line-height: 1.5;
	color: white;

	opacity: 0.6;
`;
const LicenseSubmit = styled.button`
	color: white;
	opacity: 0.6;
	font-size: 14px;
	width: 460px;
	margin-top: 20px;
	cursor: pointer;
	border-radius: 100rem;
	padding: 0.2rem 2rem;
	border: solid 2px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(101deg, var(--scalebound-blue), var(--scalebound-green));
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px var(--scalebound-main) inset;

	@media only screen and (max-width: 1200px) {
		width: 100%;
	}
`;

export default Checker;
