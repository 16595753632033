import './App.css';
import { Switch, Route } from 'react-router-dom';
import Home from './pages/Home';
import Keychecker from './pages/Keychecker';
import { useEffect, useState } from 'react';
import { getUserDetails } from './utils/api';
import { Redirect } from 'react-router';

function App() {
	const [user, setUser] = useState(null);

	useEffect(() => {
		getUserDetails()
			.then(({ data }) => {
				setUser(data);
			})
			.catch((err) => {
				return console.log(err);
			});
	}, []);

	return (
		<>
			<Switch>
				<Route
					path="/"
					exact={true}
					render={() => <Home user={user} />}
				/>
				<Route
					path="/keychecker"
					exact={true}
					render={() => <Keychecker user={user} />}
				/>
				<Route render={() => <Redirect to="/" />} />
			</Switch>
		</>
	);
}

export default App;
