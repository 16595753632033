import axios from 'axios';

export async function getUserDetails() {
	const res = await axios.get('/auth/discord/authenticated', {
		withCredentials: true,
	});
	return res;
}

export function getProducts() {
	return axios.get('/products/get', {
		withCredentials: true,
	});
}
