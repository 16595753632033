import React from 'react';
import styled from 'styled-components';
import { AiFillStar } from 'react-icons/ai';
import Fade from 'react-reveal/Fade';
function Feedback() {
	return (
		<Wrapper>
			<Container>
				<Title>Vouches</Title>
				<section id="testimonials">
					<div className="testimonial-box-container">
						<Fade left duration={800} delay={0}>
							<div className="animation">
								<div className="testimonial-box">
									<div className="box-top">
										<div className="profile">
											<div className="profile-img">
												<img
													src="https://production-data.s3.filebase.com/avatars/l/64/64534.jpg?1614958393"
													alt="User"
												/>
											</div>

											<div className="name-user">
												<strong>Automating</strong>
												<span>aSpigot customer</span>
											</div>
										</div>

										<div className="reviews">
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
										</div>
									</div>

									<div className="client-comment">
										<p>
											Amazing spigot, absolutely amazing
											for HCF/Practice serves!
										</p>
									</div>
								</div>
							</div>
						</Fade>
						<Fade right duration={800} delay={100}>
							<div className="animation">
								<div className="testimonial-box">
									<div className="box-top">
										<div className="profile">
											<div className="profile-img">
												<img
													src="https://production-data.s3.filebase.com/avatars/l/279/279355.jpg?1624931198"
													alt="User"
												/>
											</div>

											<div className="name-user">
												<strong>Bastoooon</strong>
												<span>FoxSpigot customer</span>
											</div>
										</div>

										<div className="reviews">
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
										</div>
									</div>

									<div className="client-comment">
										<p>
											FoxSpigot works well, pvp is much
											better than Spigot vanilla or paper,
											it's worth buying.
										</p>
									</div>
								</div>
							</div>
						</Fade>
						<Fade left duration={800} delay={200}>
							<div className="animation">
								<div className="testimonial-box">
									<div className="box-top">
										<div className="profile">
											<div className="profile-img">
												<img
													src="https://production-data.s3.filebase.com/avatars/l/280/280299.jpg?1615545838"
													alt="User"
												/>
											</div>

											<div className="name-user">
												<strong>solewave</strong>
												<span>wSpigot customer</span>
											</div>
										</div>

										<div className="reviews">
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
										</div>
									</div>

									<div className="client-comment">
										<p>
											Amazing spigot with amazing
											performance, and it's DIRT CHEAP!
											Really recommend this for any
											gamemodes, i use it with UHC and 124
											players fix 20tps. ITS REALLY GOOD!
										</p>
									</div>
								</div>
							</div>
						</Fade>
						<Fade right duration={800} delay={300}>
							<div className="animation">
								<div className="testimonial-box">
									<div className="box-top">
										<div className="profile">
											<div className="profile-img">
												<img
													src="https://production-data.s3.filebase.com/avatars/l/178/178533.jpg?1575066725"
													alt="User"
												/>
											</div>

											<div className="name-user">
												<strong>Elise1886 </strong>
												<span>kSpigot customer</span>
											</div>
										</div>

										<div className="reviews">
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
										</div>
									</div>

									<div className="client-comment">
										<p>
											Does exactly what it says, I have
											not had any problems with this so
											far that relate to this excellent
											spigot.
										</p>
									</div>
								</div>
							</div>
						</Fade>
						<Fade left duration={800} delay={400}>
							<div className="animation">
								<div className="testimonial-box">
									<div className="box-top">
										<div className="profile">
											<div className="profile-img">
												<img
													src="https://production-data.s3.filebase.com/avatars/l/105/105585.jpg?1635380446"
													alt="User"
												/>
											</div>

											<div className="name-user">
												<strong>Elb1to </strong>
												<span>FoxSpigot customer</span>
											</div>
										</div>

										<div className="reviews">
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
										</div>
									</div>

									<div className="client-comment">
										<p>
											Great, friendly and helpful
											developer! I definetively recommend
											buying his products :D 10/10 -
											Support, 10/10 - Spigot fork, Vouch
											for this man
										</p>
									</div>
								</div>
							</div>
						</Fade>
						<Fade right duration={800} delay={500}>
							<div className="animation">
								<div className="testimonial-box">
									<div className="box-top">
										<div className="profile">
											<div className="profile-img">
												<img
													src="https://production-data.s3.filebase.com/avatars/o/61/61940.jpg?1523747456"
													alt="User"
												/>
											</div>

											<div className="name-user">
												<strong>Rivzz </strong>
												<span>mSpigot customer</span>
											</div>
										</div>

										<div className="reviews">
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
											<AiFillStar />
										</div>
									</div>

									<div className="client-comment">
										<p>
											Fantastic PaperSpigot Fork! The
											developer Scalebound was extremely
											helpful, quick to respond to my
											questions, and even came on the
											server to make sure everything was
											running smoothly! Extremely grateful
											and cannot recommend this resource
											enough!
										</p>
									</div>
								</div>
							</div>
						</Fade>
					</div>
				</section>
			</Container>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	background-color: var(--scalebound-main);
	padding-bottom: 5rem;
`;

const Container = styled.div`
	display: flex;
	padding-top: 2rem;
	max-width: 70%;
	margin: auto;
	flex-direction: column;
	@media only screen and (max-width: 768px) {
		max-width: 90%;
		min-width: 90%;
	}

	#testimonials {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-direction: column;
		width: 100%;
	}
	.testimonial-heading {
		letter-spacing: 1px;
		margin: 30px 0px;
		padding: 10px 20px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
	}

	.testimonial-heading span {
		font-size: 1.3rem;
		color: #252525;
		margin-bottom: 10px;
		letter-spacing: 2px;
		text-transform: uppercase;
	}
	.testimonial-box-container {
		display: flex;
		gap: 2rem;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
		width: 100%;
	}
	.testimonial-box {
		width: 500px;
		box-shadow: 2px 2px 30px rgba(0, 0, 0, 0.1);
		background-color: #171627;
		padding: 20px;
		border-radius: 0.5rem;

		transition: all 0.3s ease;

		&:hover {
			transform: scale(1.02);
		}
	}
	.profile-img {
		width: 50px;
		height: 50px;
		border-radius: 50%;
		overflow: hidden;
		margin-right: 10px;
	}
	.profile-img img {
		width: 100%;
		height: 100%;
		object-fit: cover;
		object-position: center;
	}
	.profile {
		display: flex;
		align-items: center;
	}
	.name-user {
		display: flex;
		flex-direction: column;
	}
	.name-user strong {
		color: white;
		font-size: 1.1rem;
		letter-spacing: 0.5px;
	}
	.name-user span {
		color: #979797;
		font-size: 0.8rem;
	}
	.reviews {
		color: var(--scalebound-blue);
	}
	.box-top {
		display: flex;
		justify-content: space-between;
		align-items: center;
		margin-bottom: 20px;
	}
	.client-comment p {
		opacity: 0.6;
		color: white;
		font-size: 0.9rem;
		margin-bottom: 0.5rem;
		font-weight: 300;
		@media only screen and (max-width: 1200px) {
			font-size: 12px;
			line-height: 1.7;
			font-weight: 300;
			letter-spacing: -0.29px;
		}
	}

	@media (max-width: 790px) {
		.testimonial-box {
			width: 90%;
		}
		.animation {
			width: 100%;
		}
		.testimonial-heading h1 {
			font-size: 1.4rem;
		}
	}
	@media (max-width: 340px) {
		.box-top {
			flex-wrap: wrap;
			margin-bottom: 10px;
		}
		.reviews {
			margin-top: 10px;
		}
	}
	::selection {
		color: #ffffff;
		background-color: #252525;
	}
`;

const Title = styled.h3`
	font-size: 1.5rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
`;

export default Feedback;
