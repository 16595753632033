import '../Keychecker.css';

import Navbar from '../components/Navbar';
import Checker from '../components/Checker';
import styled from 'styled-components';

function Keychecker(props) {
	return (
		<>
			<Wrapper>
				<Landing>
					<Navbar user={props.user} />
					<Checker />
				</Landing>
			</Wrapper>
		</>
	);
}
const Wrapper = styled.div`
	color: #fff;
	display: flex;
	flex-direction: column;
`;
const Landing = styled.div`
	max-width: 70%;
	min-width: 70%;
	height: 100vh;
	margin: auto;
	@media only screen and (max-width: 1200px) {
		max-width: 90%;
		min-width: 90%;
	}
`;

export default Keychecker;
