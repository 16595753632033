import { Link as LinkBtn } from 'react-router-dom';
import styled from 'styled-components';

function Footer() {
	return (
		<Wrapper>
			<Container>
				<Grid>
					<LinkBtn to="/">Home</LinkBtn>
					<LinkBtn to="/keychecker">License checker</LinkBtn>
					<Link
						href="https://www.mc-market.org/members/8158/"
						target="_blank"
						rel="noreferrer"
					>
						MC-Market
					</Link>
					<Link
						href="https://discord.gg/dHt6Yduxhf"
						target="_blank"
						rel="noreferrer"
					>
						Discord{' '}
					</Link>
					<Link
						href="https://t.me/Scalebound"
						target="_blank"
						rel="noreferrer"
					>
						Telegram
					</Link>
				</Grid>
			</Container>
			<p
				style={{
					textAlign: 'center',
					marginBottom: '3rem',
					opacity: '0.3',
					fontSize: '.8rem',
				}}
			>
				{' '}
				<Link>&copy; Copyright 2021 SB-Development</Link>
			</p>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	background-color: var(--scalebound-second);
`;

const Container = styled.div`
	border-top: 1px solid #e6e6e621;
	display: flex;
	padding-top: 2rem;
	max-width: 70%;
	margin: auto;
	margin-top: 5rem;
	margin-bottom: 2rem;
	@media only screen and (max-width: 1200px) {
		max-width: 90%;
	}
	a {
		cursor: pointer;
		opacity: 0.6;
		font-size: 0.9rem;
	}
`;

const Link = styled.a`
	color: #fff;
	font-size: 0.9rem;

	opacity: 0.6;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(min(420px, 100%), 1fr));
	gap: 1rem;
	align-items: center;
	justify-content: center;
	width: 100%;
`;

export default Footer;
