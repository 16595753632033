import axios from 'axios';
import { useEffect, useRef, useState } from 'react';
import { BsDiscord, BsTelegram } from 'react-icons/bs';
import { FiLogOut } from 'react-icons/fi';
import { Link as LinkBtn, useHistory } from 'react-router-dom';
import styled from 'styled-components';
import logo from '../images/scalelogo.png';

const StyledMenu = styled.nav`
	display: flex;
	flex-direction: column;
	justify-content: center;
	background: #171627;
	transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(-100%)')};
	height: 100vh;
	text-align: right;
	z-index: 1;
	position: fixed;
	top: 0;
	right: 0;
	transition: transform 0.3s ease-in-out;

	@media (max-width: 1200px) {
		width: 100%;
	}

	a {
		font-size: 12px;
		text-transform: uppercase;
		padding: 2rem 0;
		letter-spacing: 0.5rem;
		color: white;
		opacity: 0.6;
		text-decoration: none;
		transition: color 0.3s linear;
		text-align: center;

		&:hover {
			color: var(--scalebound-blue);
		}
	}
`;

const StyledBurger = styled.button`
	position: absolute;
	top: 5.6%;
	right: 2rem;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: 1rem;
	height: 1rem;
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;

	&:focus {
		outline: none;
	}

	div {
		width: 1rem;
		height: 0.15rem;
		background: ${({ open }) => (open ? 'white' : 'white')};
		border-radius: 10px;
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;

		:first-child {
			transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? '0' : '1')};
			transform: ${({ open }) =>
				open ? 'translateX(20px)' : 'translateX(0)'};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
		}
	}
`;

const Burger = ({ open, setOpen }) => {
	return (
		<StyledBurger open={open} onClick={() => setOpen(!open)}>
			<div />
			<div />
			<div />
		</StyledBurger>
	);
};

function Navbar(props) {
	const [open, setOpen] = useState(false);
	const node = useRef();

	const user = props.user;

	const size = useWindowSize();

	const login = () => {
		// https://scalebound.herokuapp.com/auth/discord
		// http://localhost:5000/auth/discord
		return window.open(`https://scalebound.club/auth/discord`, '_self');
	};

	const logout = () => {
		// Get request to /auth/discord/logout
		axios
			.get('/auth/discord/logout')
			.then((res) => {
				window.location.href = '/';
			})
			.catch((err) => {
				return;
			});
	};

	// Hook
	function useWindowSize() {
		// Initialize state with undefined width/height so server and client renders match
		// Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
		const [windowSize, setWindowSize] = useState({
			width: undefined,
			height: undefined,
		});
		useEffect(() => {
			// Handler to call on window resize
			function handleResize() {
				// Set window width/height to state
				setWindowSize({
					width: window.innerWidth,
					height: window.innerHeight,
				});
			}
			// Add event listener
			window.addEventListener('resize', handleResize);
			// Call handler right away so state gets updated with initial window size
			handleResize();
			// Remove event listener on cleanup
			return () => window.removeEventListener('resize', handleResize);
		}, []); // Empty array ensures that effect is only run on mount
		return windowSize;
	}

	const Menu = ({ open }) => {
		return (
			<>
				<StyledMenu open={open}>
					{user ? (
						<>
							{size.width > 1200 ? null : (
								<>
									<LoginContainer>
										<Avatar src={user.discord_avatar} />
										<TextContainer>
											<Text>Logged in as</Text>
											<Text
												style={{ fontWeight: 'bolder' }}
											>
												{user.discord_tag}
											</Text>
										</TextContainer>
										<FiLogOut
											style={{ cursor: 'pointer' }}
											onClick={logout}
										/>
									</LoginContainer>
								</>
							)}
						</>
					) : (
						<LoginText onClick={login}>Login</LoginText>
					)}
					<LinkBtn to="/">Home</LinkBtn>
					<LinkBtn to="/keychecker">License checker</LinkBtn>
					<a
						href="https://www.mc-market.org/members/8158/"
						target="_blank"
						rel="noreferrer"
					>
						Mc-market
					</a>
					<a
						href="https://discord.gg/dHt6Yduxhf"
						target="_blank"
						rel="noreferrer"
					>
						Discord
					</a>
					<a
						href="https://t.me/Scalebound"
						target="_blank"
						rel="noreferrer"
					>
						Telegram
					</a>
				</StyledMenu>{' '}
			</>
		);
	};

	const history = useHistory();

	return (
		<>
			<MobileMenu>
				<div ref={node}>
					<Burger open={open} setOpen={setOpen} />
					<Menu open={open} setOpen={setOpen} />
				</div>
			</MobileMenu>
			<Container>
				<Title onClick={() => history.push('/')}>
					<Logo src={logo} alt="logo" />
					SB-
					<TitleSpan>development</TitleSpan>
				</Title>

				<Socials>
					<BsDiscord
						onClick={() =>
							window.open('https://discord.gg/dHt6Yduxhf')
						}
					/>
					<BsTelegram
						onClick={() => window.open('https://t.me/Scalebound')}
					/>
				</Socials>
				<Links>
					<LinkBtn to="/" state={user}>
						Home
					</LinkBtn>
					<LinkBtn to="/keychecker" state={user}>
						License checker
					</LinkBtn>
					<Link
						href="https://www.mc-market.org/members/8158/"
						target="_blank"
						rel="noreferrer"
					>
						MC-Market
					</Link>
					<Link
						href="https://discord.gg/dHt6Yduxhf"
						target="_blank"
						rel="noreferrer"
					>
						Discord
					</Link>
				</Links>
				{user ? (
					<>
						{size.width < 1200 ? null : (
							<LoginContainer>
								<Avatar src={user.discord_avatar} />
								<TextContainer>
									<Text>Logged in as</Text>
									<Text style={{ fontWeight: 'bolder' }}>
										{user.discord_tag}
									</Text>
								</TextContainer>
								<FiLogOut
									style={{ cursor: 'pointer' }}
									onClick={logout}
								/>
							</LoginContainer>
						)}
					</>
				) : (
					<Login onClick={login}>
						<Link>Login</Link>
					</Login>
				)}
			</Container>
		</>
	);
}
const LoginText = styled.div`
	font-size: 12px;
	text-transform: uppercase;
	padding: 2rem 0;
	letter-spacing: 0.5rem;
	color: white;
	opacity: 0.6;
	text-decoration: none;
	transition: color 0.3s linear;
	text-align: center;
	cursor: pointer;
`;

const LoginContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 15px;
	@media only screen and (max-width: 1200px) {
		justify-content: center;
		margin-bottom: 2rem;
	}
`;
const TextContainer = styled.div`
	display: flex;
	flex-direction: column;
	@media only screen and (max-width: 1200px) {
		text-align: left;
	}
`;
const Avatar = styled.img`
	width: 40px;
	height: 40px;
	border-radius: 100%;
`;
const Text = styled.p`
	font-size: small;
	opacity: 0.6;
	color: white;
`;

const Container = styled.div`
	padding: 25px 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #e6e6e621;

	a {
		cursor: pointer;
		text-transform: uppercase;
		opacity: 0.6;
	}
`;

const Title = styled.h3`
	text-transform: uppercase;
	cursor: pointer;
	font-size: 1.5rem;
	display: flex;
	align-items: center;
	@media only screen and (max-width: 1200px) {
		font-size: 1.2rem;
	}
`;

const TitleSpan = styled.span`
	color: var(--scalebound-blue);
`;

const Logo = styled.img`
	height: 70px;
	width: 70px;
`;

const Socials = styled.div`
	gap: 15px;
	display: flex;
	color: var(--scalebound-blue);

	svg {
		cursor: pointer;
	}

	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

const Links = styled.div`
	display: flex;
	gap: 40px;
	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;
const Link = styled.a`
	cursor: pointer;
	text-transform: uppercase;
	opacity: 0.6;
`;

const Login = styled.div`
	cursor: pointer;
	border-radius: 100rem;
	padding: 0.2rem 2rem;
	border: solid 2px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(101deg, var(--scalebound-blue), var(--scalebound-green));
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px var(--scalebound-main) inset;
	@media only screen and (max-width: 1200px) {
		display: none;
	}
`;

const MobileMenu = styled.div`
	font-size: 1.5rem;
	display: flex;
	@media only screen and (min-width: 1200px) {
		display: none;
	}
`;

export default Navbar;
