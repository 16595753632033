import React from 'react';
import styled from 'styled-components';
import Feedback from '../components/Feedback';
import Footer from '../components/Footer';
import Header from '../components/Header';
import Navbar from '../components/Navbar';
import Products from '../components/Products';

function Home(props) {
	return (
		<Wrapper>
			<Landing>
				<Navbar user={props.user} />
				<Header />
			</Landing>
			<Products user={props.user} />
			<Feedback />
			<Footer />
		</Wrapper>
	);
}

const Wrapper = styled.div`
	color: #fff;
	display: flex;
	flex-direction: column;
`;
const Landing = styled.div`
	max-width: 70%;
	min-width: 70%;
	height: 100vh;
	margin: auto;
	@media only screen and (max-width: 1200px) {
		max-width: 90%;
		min-width: 90%;
	}
`;

export default Home;
