import { useEffect, useState } from 'react';
import { AiOutlineArrowRight, AiOutlineDownload } from 'react-icons/ai';
import Fade from 'react-reveal/Fade';
import { Redirect } from 'react-router';
import BeatLoader from 'react-spinners/BeatLoader';
import styled from 'styled-components';
import background from '../images/wave2.svg';
import { getProducts } from '../utils/api';

function Products(props) {
	const user = props.user;

	const [loading, setLoading] = useState(null);

	const download = async (product) => {
		if (!user) return;
		if (loading) return;
		let isMounted = true;
		try {
			if (isMounted) setLoading(product);
			window.open(
				`https://scalebound.club/products/download/?product=${product}`
			);
			if (isMounted) setLoading(null);
		} catch (error) {
			return <Redirect to="/" />;
		}
		return () => {
			isMounted = false;
		};
	};

	const [products, setProducts] = useState([]);
	useEffect(() => {
		let isMounted = true;
		getProducts()
			.then((res) => {
				if (isMounted) setProducts(res.data.products);
			})
			.catch(() => {
				return;
			});
		return () => {
			isMounted = false;
		};
	}, []);

	return (
		<Wrapper>
			<Container id="products">
				<Title>Products</Title>
				<Grid>
					<Fade left duration={800} delay={0}>
						<CardContainer>
							<Card>
								<div>
									<ProductTitle>wSpigot</ProductTitle>
									<ProductDescription>
										wSpigot is an enchanced version of
										aSpigot, both are forks of 1.7.10
										paperspigot and inteded for HCF servers.
										wSpigot offers more customizable values
										for your knockback profiles than
										aSpigot.
										<br />
										<br />
										It's performing better than aSpigot and
										has more knockback configurability.
									</ProductDescription>
								</div>
								<div>
									<ProductPrice>$12.00</ProductPrice>
									<ProductButtonRow>
										<ProductButton data-sellix-product="6160344638e7f">
											Purchase
										</ProductButton>
										{user ? (
											<DownloadLink
												onClick={() =>
													(window.location.href =
														'https://customer.scaleboud.club/')
												}
												style={
													products.includes('wSpigot')
														? {
																color: 'var(--scalebound-blue)',
														  }
														: { display: 'none' }
												}
											>
												{loading === 'wSpigot' ? (
													<BeatLoader
														color={
															'var(--scalebound-blue)'
														}
														size={10}
														loading={loading}
													/>
												) : (
													<>
														Download{' '}
														<AiOutlineDownload />
													</>
												)}
											</DownloadLink>
										) : null}

										<ProductLink
											href="https://www.mc-market.org/resources/6874/"
											target="_blank"
											rel="noreferrer"
										>
											MC-Market <AiOutlineArrowRight />
										</ProductLink>
									</ProductButtonRow>
								</div>
							</Card>
						</CardContainer>
					</Fade>

					<Fade bottom duration={800} delay={0}>
						<CardContainer>
							<MostPopular>Most Popular</MostPopular>
							<Card className="featured">
								<div>
									<ProductTitle>FoxSpigot</ProductTitle>
									<ProductDescription>
										FoxSpigot is a good PaperSpigot fork
										made for PvP servers! FoxSpigot offers a
										lot of customizable values for your
										knockback profiles; over 16 values in
										fact!
										<br />
										<br />
										It's performance is also decent
										considering it's low price. FoxSpigot
										also has a Developer API to hook your
										Practice plugin in it and have different
										KB-Profiles per kit!
									</ProductDescription>
								</div>
								<div>
									<ProductPrice>$15.00</ProductPrice>
									<ProductButtonRow>
										<ProductButton data-sellix-product="61602db531992">
											Purchase
										</ProductButton>

										{user ? (
											<DownloadLink
												onClick={() =>
													(window.location.href =
														'https://customer.scaleboud.club/')
												}
												style={
													products.includes(
														'FoxSpigot'
													)
														? {
																color: 'var(--scalebound-blue)',
														  }
														: { display: 'none' }
												}
											>
												{loading === 'FoxSpigot' ? (
													<BeatLoader
														color={
															'var(--scalebound-blue)'
														}
														size={10}
														loading={loading}
													/>
												) : (
													<>
														Download{' '}
														<AiOutlineDownload />
													</>
												)}
											</DownloadLink>
										) : null}
										<ProductLink
											href="https://www.mc-market.org/resources/8592/"
											target="_blank"
											rel="noreferrer"
										>
											MC-Market <AiOutlineArrowRight />
										</ProductLink>
									</ProductButtonRow>
								</div>
							</Card>
						</CardContainer>
					</Fade>

					<Fade right duration={800} delay={0}>
						<CardContainer>
							<Card>
								<div>
									<ProductTitle>aSpigot</ProductTitle>
									<ProductDescription>
										aSpigot is a good budget spigot for
										starting HCF servers. aSpigot offers
										customizable knockback and HCF features
										for low money.
										<br />
										<br />
										Performance is definitely better than
										normal 1.7.10 spigot, but if you are
										going to get alot of players you should
										check out wSpigot.
									</ProductDescription>
								</div>
								<div>
									<ProductPrice>$8.00</ProductPrice>
									<ProductButtonRow>
										<ProductButton data-sellix-product="616033eae3cf4">
											Purchase
										</ProductButton>

										{user ? (
											<DownloadLink
												onClick={() =>
													(window.location.href =
														'https://customer.scaleboud.club/')
												}
												style={
													products.includes('aSpigot')
														? {
																color: 'var(--scalebound-blue)',
														  }
														: { display: 'none' }
												}
											>
												{loading === 'aSpigot' ? (
													<BeatLoader
														color={
															'var(--scalebound-blue)'
														}
														size={10}
														loading={loading}
													/>
												) : (
													<>
														Download{' '}
														<AiOutlineDownload />
													</>
												)}
											</DownloadLink>
										) : null}
										<ProductLink
											href="https://www.mc-market.org/resources/6101/"
											target="_blank"
											rel="noreferrer"
										>
											MC-Market <AiOutlineArrowRight />
										</ProductLink>
									</ProductButtonRow>
								</div>
							</Card>
						</CardContainer>
					</Fade>

					<Fade left duration={800} delay={300}>
						<CardContainer>
							<Card>
								<div>
									<ProductTitle>mSpigot</ProductTitle>
									<ProductDescription>
										mSpigot is a go-to TacoSpigot fork
										intended for FFA/Practice servers!
										mSpigot offers basic knockback
										customizability and enchanced bow
										boosting.
										<br />
										<br />
										Performance is good for a 10USD spigot,
										but if you are looking for more
										perfomance and customizability you
										should check out FoxSpigot.
									</ProductDescription>
								</div>
								<div>
									<ProductPrice>$10.00</ProductPrice>
									<ProductButtonRow>
										<ProductButton data-sellix-product="6160337fb58fe">
											Purchase
										</ProductButton>

										{user ? (
											<DownloadLink
												onClick={() =>
													(window.location.href =
														'https://customer.scaleboud.club/')
												}
												style={
													products.includes('mSpigot')
														? {
																color: 'var(--scalebound-blue)',
														  }
														: { display: 'none' }
												}
											>
												{loading === 'mSpigot' ? (
													<BeatLoader
														color={
															'var(--scalebound-blue)'
														}
														size={10}
														loading={loading}
													/>
												) : (
													<>
														Download{' '}
														<AiOutlineDownload />
													</>
												)}
											</DownloadLink>
										) : null}
										<ProductLink
											href="https://www.mc-market.org/resources/6864/"
											target="_blank"
											rel="noreferrer"
										>
											MC-Market <AiOutlineArrowRight />
										</ProductLink>
									</ProductButtonRow>
								</div>
							</Card>
						</CardContainer>
					</Fade>

					<Fade bottom duration={800} delay={300}>
						<CardContainer>
							<Card className="featured">
								<div>
									<ProductTitle>kSpigot</ProductTitle>
									<ProductDescription>
										kSpigot is a good option for servers
										that want to keep the 1.8 combat on a
										1.12.2 server. It offers basic
										customizable knockback modifications and
										a bunch of useful performance
										improvements.
									</ProductDescription>
								</div>
								<div>
									<ProductPrice>$20.00</ProductPrice>
									<ProductButtonRow>
										<ProductButton data-sellix-product="616034895b126">
											Purchase
										</ProductButton>

										{user ? (
											<DownloadLink
												onClick={() =>
													(window.location.href =
														'https://customer.scaleboud.club/')
												}
												style={
													products.includes('kSpigot')
														? {
																color: 'var(--scalebound-blue)',
														  }
														: { display: 'none' }
												}
											>
												{loading === 'kSpigot' ? (
													<BeatLoader
														color={
															'var(--scalebound-blue)'
														}
														size={10}
														loading={loading}
													/>
												) : (
													<>
														Download{' '}
														<AiOutlineDownload />
													</>
												)}
											</DownloadLink>
										) : null}
										<ProductLink
											href="https://www.mc-market.org/resources/12302/"
											target="_blank"
											rel="noreferrer"
										>
											MC-Market <AiOutlineArrowRight />
										</ProductLink>
									</ProductButtonRow>
								</div>
							</Card>
						</CardContainer>
					</Fade>

					<Fade bottom duration={800} delay={300}>
						<CardContainer>
							<Card className="featured">
								<div>
									<ProductTitle>pSpigot</ProductTitle>
									<ProductDescription>
										pSpigot is custom 1.8.8 Paper spigot
										fork with improved performance and pvp
										mechanics. iHCF Events, Entity hider, In
										Game Editable knockback, and more.
									</ProductDescription>
								</div>
								<div>
									<ProductPrice>$30.00</ProductPrice>
									<ProductButtonRow>
										<ProductButton data-sellix-product="622b726d27dbf">
											Purchase
										</ProductButton>

										{user ? (
											<DownloadLink
												onClick={() =>
													(window.location.href =
														'https://customer.scaleboud.club/')
												}
												style={
													products.includes('pSpigot')
														? {
																color: 'var(--scalebound-blue)',
														  }
														: { display: 'none' }
												}
											>
												{loading === 'pSpigot' ? (
													<BeatLoader
														color={
															'var(--scalebound-blue)'
														}
														size={10}
														loading={loading}
													/>
												) : (
													<>
														Download{' '}
														<AiOutlineDownload />
													</>
												)}
											</DownloadLink>
										) : null}
										<ProductLink
											href="https://www.mc-market.org/resources/23095/"
											target="_blank"
											rel="noreferrer"
										>
											MC-Market <AiOutlineArrowRight />
										</ProductLink>
									</ProductButtonRow>
								</div>
							</Card>
						</CardContainer>
					</Fade>

					<Fade right duration={800} delay={300}>
						<CardContainer>
							<Card>
								<div>
									<ProductTitle>KohiSpigot</ProductTitle>
									<ProductDescription>
										KohiSpigot is a free spigot that you can
										use if you are on a very low budget.
										KohiSpigot offers you small performance
										fixes and a kohi alike knockback
									</ProductDescription>
								</div>
								<div>
									<ProductPrice>FREE</ProductPrice>
									<ProductButtonRow>
										<ProductButton
											onClick={() =>
												download('KohiSpigot')
											}
										>
											Download
										</ProductButton>
									</ProductButtonRow>
								</div>
							</Card>
						</CardContainer>
					</Fade>
				</Grid>
			</Container>
		</Wrapper>
	);
}

const Wrapper = styled.div`
	background-color: var(--scalebound-second);
	padding-bottom: 5rem;
`;

const Title = styled.h3`
	font-size: 1.5rem;
	margin-bottom: 1rem;
	display: flex;
	align-items: center;
`;

const Container = styled.div`
	display: flex;
	padding-top: 2rem;
	max-width: 70%;
	margin: auto;
	flex-direction: column;
	@media only screen and (max-width: 768px) {
		max-width: 90%;
	}
`;

const Grid = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fill, minmax(min(420px, 100%), 1fr));
	gap: 1rem;
	align-items: center;
	justify-content: center;
	width: 100%;
	max-width: 100%;
	@media only screen and (max-width: 1200px) {
		gap: 2rem;
	}

	.featured {
		height: 280px;
		@media only screen and (max-width: 1200px) {
			height: 250px;
		}
	}
`;

const MostPopular = styled.div`
	background: linear-gradient(
		101deg,
		var(--scalebound-blue),
		var(--scalebound-green)
	);
	color: var(--scalebound-main);
	border-radius: 1rem;
	width: 150px;
	text-align: center;
	margin: auto;
	margin-top: -2rem;
`;

const ProductTitle = styled.h4`
	padding-bottom: 0.25rem;
	margin-bottom: 0.5rem;
	border-bottom: 1px solid #e6e6e621;
`;
const ProductDescription = styled.p`
	opacity: 0.6;
	color: white;
	font-size: 0.9rem;
	margin-bottom: 0.5rem;
	font-weight: 300;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
		line-height: 1.7;
		font-weight: 300;
		letter-spacing: -0.29px;
	}
`;
const ProductPrice = styled.p`
	color: var(--scalebound-blue);
	display: flex;
	font-size: 1.2rem;
`;
const ProductLink = styled.a`
	color: white;
	display: flex;
	align-items: center;
	gap: 0.3rem;
	font-size: 0.9rem;
	opacity: 0.6;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
	}
`;
const DownloadLink = styled.p`
	color: white;
	cursor: pointer;
	display: flex;
	align-items: center;
	gap: 0.3rem;
	font-size: 0.9rem;
	opacity: 0.6;
	@media only screen and (max-width: 1200px) {
		font-size: 12px;
	}
`;
const ProductButtonRow = styled.div`
	display: flex;
	gap: 0.8rem;
	align-items: center;
	text-align: center;
`;
const ProductButton = styled.button`
	color: white;
	opacity: 0.6;
	font-size: 18px;
	padding-top: 20px;
	cursor: pointer;
	border-radius: 100rem;
	padding: 0.2rem 2rem;
	border: solid 2px transparent;
	background-image: linear-gradient(
			rgba(255, 255, 255, 0),
			rgba(255, 255, 255, 0)
		),
		linear-gradient(101deg, var(--scalebound-blue), var(--scalebound-green));
	background-origin: border-box;
	background-clip: content-box, border-box;
	box-shadow: 2px 1000px 1px var(--scalebound-main) inset;
	@media only screen and (max-width: 1200px) {
		font-size: 14px;
	}
`;
const CardContainer = styled.div`
	display: flex;
	flex-direction: column;
	border-radius: 0.5rem;
	padding: 15px;
	background-color: #171627;
	transition: all 0.3s ease;
	background-image: url(${background});
	background-size: cover;

	&:hover {
		transform: scale(1.02);
	}
`;
const Card = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	height: 260px;
`;

export default Products;
